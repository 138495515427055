/**
 * @description: 店铺配置数据
 */
export const mallTypeList = [
    {
        type: 'mall',
        title: '微店铺',
        subTitle: ' E-commence'
    },
    {
        type: 'model',
        title: '店铺模板',
        subTitle: ' E-commenceModel'
    },
]

export const mallIndustryList = [
    { label: '日用百货', value: 'department' },
    { label: '服饰饰品', value: 'clothes' },
    { label: '休闲零食', value: 'snacks' },
    { label: '数码家电', value: 'digital' },
    { label: '教育培训', value: 'education' },
    { label: '酒店旅游', value: 'hotel' },
    { label: '其他', value: 'other' },
]